import React, { useEffect, useState } from "react"
import ItemModal from "../components/itemModal"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CsvDownloadButton from "../components/csvDownloadButton"
import { capitalize } from "../components/utilities"
import { useLocation } from "@reach/router"
import qs from 'qs';

import {
  ClearRefinements,
  Configure,
  connectCurrentRefinements,
  connectStateResults,
  CurrentRefinements,
  Highlight,
  Hits,
  HitsPerPage,
  InstantSearch,
  Pagination,
  RangeInput,
  RefinementList,
  SearchBox,
  SortBy
} from 'react-instantsearch-dom';
import {
  Header,
  Icon,
  Segment
} from 'semantic-ui-react'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

const searchClient = instantMeiliSearch(
  "https://admin.curranindex.org/data",
  "",
  {
    primaryKey: 'id',
    paginationTotalHits: 9999,
    finitePagination: true
  }
);

const SearchResultsCount = ({ searchResults }) => {
  return (
    <div>
      { searchResults &&
        <h4>{ (searchResults.nbHits).toLocaleString() } results</h4>
      }
    </div>
  );
};

const DisplaySearchResultsCount = connectStateResults(SearchResultsCount);

const CsvDownload = ({ searchState }) => (
  <CsvDownloadButton
    index={"contributor"}
    searchState={searchState}
  />
);

const DisplayCsvDownloadButton = connectStateResults(CsvDownload);

const Contributors = () => (
  <Layout>
    <Seo title="Contributors" />
    <InstantSearch
      indexName="contributors"
      searchClient={searchClient}
    >
      <Configure
        hitsPerPage={9999}
      />
      <SearchBox />
      <DisplaySearchResultsCount />
      <CurrentRefinements />
      <div>
        <ClearRefinements />
        <HitsPerPage
          defaultRefinement={20}
          items={[
            { value: 20, label: 'Show 20 results per page' },
            { value: 50, label: 'Show 50 results per page' },
            { value: 100, label: 'Show 100 results per page' },
          ]}
        />
      </div>
      <SortBy
        defaultRefinement="contributors:full_name:asc"
        items={[
          { label: 'Sort by Name (Ascending)', value: 'contributors:full_name:asc' },
          { label: 'Sort by Name (Descending)', value: 'contributors:full_name:desc' },
          { label: 'Sort by Birth Year (Ascending)', value: 'contributors:birth_year:asc' },
          { label: 'Sort by Birth Year (Descending)', value: 'contributors:birth_year:desc' },
          { label: 'Sort by Death Year (Ascending)', value: 'contributors:death_year:asc' },
          { label: 'Sort by Death Year (Descending)', value: 'contributors:death_year:desc' },
          { label: 'Sort by Gender (Ascending)', value: 'contributors:gender:asc' },
          { label: 'Sort by Gender (Descending)', value: 'contributors:gender:desc' }
        ]}
      />
      <div className="browse">
        <Segment className="facets">
          <DisplayCsvDownloadButton />
          <Segment>
            <Header as="h3">Gender</Header>
            <RefinementList
              attribute="gender"
              showMore
              showMoreLimit={100}
              transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)
                .map(item => ({
                  ...item,
                  label: capitalize(item.label)
                }))
              }
            />
          </Segment>
          <Segment>
            <Header as="h3">Nationality</Header>
            <RefinementList
              attribute="nationalities"
              showMore
              showMoreLimit={100}
              transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)
                .map(item => ({
                  ...item,
                  label: capitalize(item.label)
                }))
              }
            />
          </Segment>
          <Segment>
            <Header as="h3">Education</Header>
            <RefinementList
              attribute="education"
              showMore
              showMoreLimit={100}
              transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)
                .map(item => ({
                  ...item,
                  label: capitalize(item.label)
                }))
              }
            />
          </Segment>
          <Segment>
            <Header as="h3">Birth Year</Header>
            <RangeInput
              attribute="birth_year"
              translations={{
                submit: 'OK'
              }}
            />
          </Segment>
          <Segment>
            <Header as="h3">Death Year</Header>
            <RangeInput
              attribute="death_year"
              translations={{
                submit: 'OK'
              }}
            />
          </Segment>
        </Segment>
        <Segment className="results">
          <Hits hitComponent={Hit} />
        </Segment>
      </div>
      <Pagination />
    </InstantSearch>
  </Layout>
)

const Hit = ({ hit }) => {
  const location = useLocation();
  const urlToSearchState = ({ search }) => qs.parse(search.slice(1));
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (urlToSearchState(location).showModal && (urlToSearchState(location).showModal === 'true') !== showModal) {
      setShowModal(urlToSearchState(location).showModal === 'true')
    }
  }, [location])

  return (
    <Segment
      className="hit contributor"
      key={hit.id}
      onClick={() => {
        if (!showModal) {
          setShowModal(true)
        }
      }}
    >
      { showModal &&
        <ItemModal
          selection={{ index: 'contributor', id: hit.id }}
          onClose={() => setShowModal(false)}
          showModal={showModal}
        />
      }
      <Header as="h3">
        <Highlight attribute="full_name" hit={hit} />
      </Header>
      <Header as="h5">
        {hit.birth_year} - {hit.death_year}
      </Header>
      <div style={{fontSize: "1em"}}>
        <Icon name="world" size="small" />
        {hit.nationalities.join(", ")}
      </div>
      <div style={{fontSize: "1em"}}>
        <Icon name="user" size="small" />
        {capitalize(hit.gender)}
      </div>
    </Segment>
  );
}

export default Contributors
